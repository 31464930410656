import BannerStyleWrapper from './Banner.style';

import MyAccount from 'sections/MyAccount';

const Banner = () => {
  return (
    <>
      <BannerStyleWrapper>
        <div className="container">
          <MyAccount />
        </div>
      </BannerStyleWrapper>
    </>
  );
};

export default Banner;
