const data = [
  {
    title: "Initial",
    value: "10",
  },
  {
    title: "Partnership",
    value: "15",
  },
  {
    title: "Marketing",
    value: "15",
  },
  {
    title: "Exchange",
    value: "20",
  },
  {
    title: "Platform",
    value: "20",
  },
  {
    title: "Budget",
    value: "20",
  },
];

export default data;
