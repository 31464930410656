import ProgressBar from 'components/progressBar';
import CardHover from 'components/cardHover';
import ProjectCardStyleWrapper from './ProjectCard.style';

const ProjectCard = ({
  id,
  title,
  votes,
  percentage,
  myVotes,
  price,
  launchedDate,
  totalRised,
  progress,
}) => {
  return (
    // <a href="/projects-details-1">
    <ProjectCardStyleWrapper>
      <div className="previous-item hover-shape-border hover-shape-inner">
        <div className="previous-gaming">
          <div className="previous-price">
            <h4 className="mb-10">{title}</h4>
            {/* <div className="dsc">PRICE (FSC) = {price} BUSD</div> */}
          </div>
        </div>
        <div className="previous-chaining">
          <span>{votes}</span>
        </div>
        <div className="previous-raise">
          <span>{Number(price) * Number(votes)} METAS</span>
          <ProgressBar progress={percentage} />
        </div>
        <div className="previous-my">{myVotes}</div>
        <CardHover />
      </div>
    </ProjectCardStyleWrapper>
    // </a>
  );
};

export default ProjectCard;
