import cardBgShape from 'assets/images/bg/card-bg-shape-small.png';
import styled from 'styled-components';

const ProjectCardStyleWrapper = styled.div`
  margintop: 200px;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card {
    margin: 0 60px 0 60px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1a1b30;
  }
  h2 {
    display: block;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .row-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    margin-bottom: 20px;
    gap: 10px;
  }
  @media only screen and (max-width: 767px) {
    .card {
      margin: 0 20px 0 20px;
    }
    .row-item {
      width: 100%;
    }
  }
`;

export default ProjectCardStyleWrapper;
