import { FiChevronRight } from "react-icons/fi";
import { SectionTitle } from "components/sectionTitle";
import Button from "components/button";
import data from "assets/data/tutorial/dataV2";
import earthThumb from "assets/images/icons/earthBg.svg";
import lockerThumb from "assets/images/icons/locker-dynamic-color.png";
import TutorialStyleWrapper from "./Tutorial.style";

const Tutorial = () => {
  return (
    <TutorialStyleWrapper id="benefits">
      <div className="container">
        <div className="row">
          <div className="col-md-6 left_content">
            <SectionTitle title="Benefits" subtitle="Holder" />
            <p>
              We aim to distribute dividends to token investors by the end of
              each month, within 10 days as a principle. Additionally, we intend
              to offer the following various benefits to holders
            </p>
            {/* <Button href="/kyc-1" sm variant="outline">
              {" "}
              Verify KYC <FiChevronRight />
            </Button> */}
          </div>
          <div className="col-md-6 right_content">
            {data?.map((step, i) => (
              <div key={i} className="participate-item d-flex">
                <div className="number-image">
                  <img src={step.icon} alt="Participate icon" />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">{step.title}</h4>
                  <p className="description">{step.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <img
        className="img-fluid bg_shape earth_thumb"
        src={earthThumb}
        alt="img"
      ></img>
      <img
        className="img-fluid bg_shape locker_thumb"
        src={lockerThumb}
        alt="img"
      ></img>
    </TutorialStyleWrapper>
  );
};

export default Tutorial;
