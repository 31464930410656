import numberImg1 from "assets/images/icons/One.svg";
import numberImg2 from "assets/images/icons/two.svg";
import numberImg3 from "assets/images/icons/three.svg";

const data = [
  {
    icon: numberImg1,
    title: "Early Investors",
    text: "Early investors have the right to claim ownership in Metaf Group proportional to the actual tokens they have invested in. Furthermore, they will be given priority in the redemption of tokens and stakes in the future.",
  },
  {
    icon: numberImg2,
    title: "Long-term Holders",
    text: "Providing services that motivate long-term token holders is one of the best ways to increase the value of the tokens. These incentives are designed to reward those who hold their tokens for an extended period.",
  },
  {
    icon: numberImg3,
    title: "Partnership Benefits",
    text: "Holders of the MetaS token can enjoy benefits from specific partnership agreements. For example, this may include discounts on certain affiliated products or services.",
  },
];

export default data;
