import { FaCheck } from "react-icons/fa";
import { SectionTitle } from "components/sectionTitle";
import rightThumb from "assets/images/homeV2/Platform_Chain.png";
import IntegrationStyleWrapper from "./Integration.style";

const Integration = () => {
  return (
    <IntegrationStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="integration_left">
              <img src={rightThumb} alt="integration thumb " />
            </div>
          </div>
          <div className="col-md-6">
            <div className="integration_content_right">
              <SectionTitle title="on Blockchain" subtitle="Ecosystem" />
              <div className="integration_content">
                <p>
                  METAF Group is creating a metaverse platform by fostering a
                  diverse ecosystem that includes NFTs, crypto, and gaming,
                  through its independent operations.
                </p>
                <div className="integration_features">
                  <span>
                    {" "}
                    <FaCheck /> META FASTEST
                  </span>
                  <span>
                    {" "}
                    <FaCheck />
                    METAF EXCHANGE
                  </span>
                  <span>
                    {" "}
                    <FaCheck />
                    Tron
                  </span>
                  <span>
                    {" "}
                    <FaCheck />
                    Ethereum
                  </span>
                  <span>
                    {" "}
                    <FaCheck />
                    METAF Token
                  </span>
                  <span>
                    {" "}
                    <FaCheck />
                    METAS Token
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IntegrationStyleWrapper>
  );
};

export default Integration;
