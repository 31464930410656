import thumb1 from "assets/images/icons/Key_icon1.png";
import thumb2 from "assets/images/icons/Key_icon2.png";
import thumb3 from "assets/images/icons/Key_icon3.png";
import thumb4 from "assets/images/icons/Key_icon4.png";

const data = [
  {
    icon: thumb1,
    title: "Initial Investment",
    text: "We aim to provide an opportunity for early investment prior to the official opening through formal announcements. Alongside the launch of MetaS tokens, we plan to distribute the tokens through a scheduled lock-up period",
  },
  {
    icon: thumb2,
    title: "Purchase on Exchanges",
    text: "MetaS tokens can be acquired by exchanging other cryptocurrencies (e.g., USDT, Bitcoin, Ethereum, etc.) on exchanges where MetaS tokens are listed. They are primarily traded against major cryptocurrencies like BTC, ETH, and USDT.",
  },
  {
    icon: thumb3,
    title: "Token Sales",
    text: "In the event of an Initial Coin Offering (ICO) or a token sale, you can purchase coins by participating in these events. For this, you can find out how to participate and the schedule through the official website of the project or its social media channels.",
  },
  {
    icon: thumb4,
    title: "Reward Programs",
    text: "There are opportunities to receive MetaS tokens as rewards by participating in exchange or project engagement programs. For example, this could include airdrops, exchange reward programs, staking, or referral programs.",
  },
];

export default data;
