import GlobalStyles from 'assets/styles/GlobalStyles';
import Layout from 'components/layout';
import MetamaskModal from 'components/modal/metamaskModal/MetamaskModal';
import WalletModal from 'components/modal/walletModal/WalletModal';
import { Fragment } from 'react';
import Banner from 'sections/Banner/v1';
import Footer from 'sections/Footer/v1';
import Header from 'sections/Header/v1';
import HolderTable from 'sections/HolderPage/HolderTable';

import { useModal } from 'utils/ModalContext';

export default function HolderPage() {
  const { walletModalvisibility, metamaskModal } = useModal();
  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        <Banner />
        <HolderTable />
      </Layout>
    </Fragment>
  );
}
