import HolderStyleWrapper from './index.style';

const HolderTable = () => {
  return (
    <HolderStyleWrapper id="holder">
      <h4 className="widget_title">Holders</h4>
      <h4 className="widget_title">A total of 8,127 token holder accounts</h4>
      <div className="holder_table">
        <table>
          <tbody>
            <tr className="table-header">
              <th>Rank</th>
              <th>Address</th>
              <th>Amount</th>
              <th>Percentage</th>
              {/* <th>Latest Txn Time (UTC)</th> */}
            </tr>
            {dummy.map((item, index) => (
              <tr key={index}>
                <td>{item.rank}</td>
                <td>{item.address}</td>
                <td>{item.amount}</td>
                <td>{item.percentage}</td>
                {/* <td>{new Date().toUTCString()}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </HolderStyleWrapper>
  );
};

export default HolderTable;

const dummy = [
  {
    rank: 1,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 2,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 3,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 4,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 5,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 6,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 7,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 8,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 9,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
  {
    rank: 10,
    address: '0x1234567890',
    amount: '1000',
    lock: '1000',
    unlock: '1000',
    lockDate: '01.01.2022',
    unlockDate: '01.01.2023',
    percentage: '50%',
  },
];
