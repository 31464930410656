import ProgressBar from 'components/progressBar';
import CardHover from 'components/cardHover';
import MyAccountWrapper from './MyAccount.style';

const MyAccount = ({
  thumb,
  title,
  price,
  launchedDate,
  totalRised,
  progress,
  coinIcon,
}) => {
  return (
    <MyAccountWrapper>
      <div className="container">
        <div className="card">
          <div className="row">
            <h2>
              MY ACCOUNT <a href="#">{'TSWN6VqgngjGvQ7KPahZfn6TZATXHTu6DZ'}</a>
            </h2>
          </div>
          <div className="row">
            <div className="row-item">
              <span>Available METAS Balance </span>
              <span>0</span>
            </div>
            <div className="row-item">
              <span>Available Votes / Total Votes </span>
              <span>0 / 0</span>
            </div>
            <div className="row-item">
              <span>Claimable METAS Rewards </span>
              <span>0</span>
            </div>
          </div>
        </div>
      </div>
    </MyAccountWrapper>
  );
};

export default MyAccount;
