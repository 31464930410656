import { useEffect, useState } from 'react';
import { useModal } from 'utils/ModalContext';
import MobileMenu from '../MobileMenu/MobileMenu';
import NavWrapper from './Header.style';
// import data from 'assets/data/menu/menuData';
import logo from 'assets/images/icons/logo_text.png';

const Header = () => {
  const { walletModalHandle } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  const data = [];
  // *******Comment out this code blew if you want to use sticky menu *******
  useEffect(() => {
    const header = document.getElementById('navbar');
    const handleScroll = window.addEventListener('scroll', () => {
      if (window.pageYOffset > 50) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });

    return () => {
      window.removeEventListener('sticky', handleScroll);
    };
  }, []);

  const handleWalletBtn = (e) => {
    e.preventDefault();
    walletModalHandle();
  };

  return (
    <NavWrapper className="gamfi_header" id="navbar">
      <div className="container">
        {/* Main Menu Start */}
        <div className="gamfi_menu_sect">
          <div className="gamfi_menu_left_sect">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
          <div className="gamfi_menu_right_sect gamfi_v1_menu_right_sect">
            <div style={{ display: 'flex', gap: '30px' }}>
              <a style={{ color: 'wheat' }} href="/projects-list">
                Votes
              </a>
              <a style={{ color: 'wheat' }} href="/holders">
                Holders
              </a>
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {/* <!-- mobile menu --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;

/*
<div className="gamfi_menu_right_sect gamfi_v1_menu_right_sect">
<div className="gamfi_menu_list">
  <ul>
    
    {data?.map((menu, i) => (
      <li key={i}>
        <a href={menu.url}>
          {menu.title}{' '}
          {menu.subMenus?.length > 0 && (
            <MdOutlineKeyboardArrowDown />
          )}
        </a>

        
        {menu.subMenus?.length > 0 && (
          <ul className="sub_menu_list">
            {menu.subMenus?.map((subMenu, i) => (
              <li key={i}>
                <a href={subMenu.url}>
                  {subMenu.title}{' '}
                  {subMenu?.subMenuChilds?.length > 0 && (
                    <MdOutlineKeyboardArrowRight />
                  )}
                </a>

                
                {subMenu?.subMenuChilds?.length > 0 && (
                  <ul className="sub_menu_child_list">
                    {subMenu?.subMenuChilds?.map((subChild, i) => (
                      <li key={i}>
                        <a href={subChild.url}>
                          {' '}
                          {subChild.title}{' '}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
</div>
<div className="gamfi_menu_btns">
  <button className="menu_btn" onClick={() => handleMobileMenu()}>
    <MdNotes />
  </button>

  <div className="wallet_btn">
    Buy token <MdOutlineKeyboardArrowDown />
    <div className="wallet_token_list">
      <a href="#">
        {' '}
        <img src={walletIcon1} alt="icon" /> PancakeSwap{' '}
      </a>
      <a href="#">
        {' '}
        <img src={walletIcon2} alt="icon" /> UniSwap
      </a>
      <a href="#">
        {' '}
        <img src={walletIcon3} alt="icon" /> CoinMarketCap
      </a>
      <a href="#">
        {' '}
        <img src={walletIcon4} alt="icon" /> Gate.io
      </a>
    </div>
  </div>
  <Button
    href="# "
    sm
    variant="white"
    className="connect_btn"
    onClick={(e) => handleWalletBtn(e)}
  >
    <img src={connectIcon} alt="icon" />
    Connect
  </Button>
</div>
</div>
*/
