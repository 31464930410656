import facebook from 'assets/images/icons/facebook.svg';
import discord from 'assets/images/icons/discord.svg';
import insta from 'assets/images/icons/insta.svg';
import youtube from 'assets/images/icons/youtube.svg';
import tiktok from 'assets/images/icons/tiktok.svg';
import telegram from 'assets/images/icons/telegram.svg';
import twitter from 'assets/images/icons/twitter.svg';
const data = [
  {
    icon: facebook,
    url: 'https://www.facebook.com/metaStoken007',
  },
  {
    icon: discord,
    url: 'https://discord.gg/NAZwPE4g',
  },
  {
    icon: insta,
    url: 'https://www.instagram.com/metas.token',
  },
  {
    icon: youtube,
    url: 'https://www.youtube.com/@MetaSToken007',
  },
  {
    icon: tiktok,
    url: 'https://www.tiktok.com/@metas.token',
  },
  {
    icon: telegram,
    url: 'https://t.me/metasglobal',
  },
  {
    icon: twitter,
    url: 'https://twitter.com/TMetas30340',
  },
];

export default data;
