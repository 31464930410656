import checkIcon from "assets/images/kycimg/RMcheckicon.svg";
import minusIcon from "assets/images/kycimg/munies.svg";

export default {
  data: [
    {
      year: "2024",
      items: [
        {
          title: "Q1 2024",
          isLunched: true,
          checkList: [
            {
              icon: checkIcon,
              text: "Exchange Opening",
            },
            {
              icon: checkIcon,
              text: "Initial Investment",
            },
            {
              icon: checkIcon,
              text: "Staking",
            },
          ],
        },
        {
          title: "Q2 2024",
          isLunched: false,
          checkList: [
            {
              icon: checkIcon,
              text: "Membership exceeds 200,000",
            },
          ],
        },
        {
          title: "Q3 2024",
          isLunched: false,
          checkList: [
            {
              icon: checkIcon,
              text: "Support for over 10 projects",
            },
          ],
        },
        {
          title: "Q4 2024",
          isLunched: false,
          checkList: [
            {
              icon: checkIcon,
              text: "Expansion of 5 global branches",
            },
          ],
        },
      ],
    },
  ],
};
