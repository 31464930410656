import { useState } from 'react';
import ProjectCard from '../ProjectCard/ProjectCard';
import ProjectItemsStyleWrapper from './ProjectItems.style';

const ProjectItems = () => {
  const [votes, setVotes] = useState([
    {
      id: 1,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 2,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 3,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 4,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 5,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 6,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 7,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 8,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 9,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 10,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 11,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 12,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 13,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
    {
      id: 14,
      title: 'Meta World',
      votes: '1000',
      percentage: '50%',
      myVotes: '100',
      price: '0.33',
      launchedDate: '05',
      totalRised: '99,0000',
      progress: '98%',
    },
  ]);
  return (
    <ProjectItemsStyleWrapper>
      <div className="container">
        <div>
          <ul className="menu-list">
            <li>Name</li>
            <li>Votes</li>
            <li>Percentage</li>
            <li>My votes</li>
          </ul>
        </div>
        <div className="projects-row">
          <div className="row tabs-row">
            {votes?.map((project, i) => (
              <div key={i} className="col-md-12">
                <ProjectCard {...project} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </ProjectItemsStyleWrapper>
  );
};

export default ProjectItems;
