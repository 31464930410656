import ProjectItems from './ProjectItems/ProjectItems';
import ProjectsListStyleWrapper from './ProjectsList.style';

const ProjectsList = () => {
  return (
    <ProjectsListStyleWrapper>
      <ProjectItems />
    </ProjectsListStyleWrapper>
  );
};

export default ProjectsList;
