import GlobalStyles from 'assets/styles/GlobalStyles';
import Layout from 'components/layout';
import MetamaskModal from 'components/modal/metamaskModal/MetamaskModal';
import WalletModal from 'components/modal/walletModal/WalletModal';
import { Fragment } from 'react';
import Banner from 'sections/Banner/v3';
import Features from 'sections/Features/v2';
import Footer from 'sections/Footer/v1';
import Header from 'sections/Header/v1';
import Integration from 'sections/Integration/v1';
import Statistics from 'sections/Statistics/v2';
import StatisticsHor from 'sections/Statistics/v1';
import Tutorial from 'sections/Tutorial/v2';
import { useModal } from 'utils/ModalContext';
import RoadMap from 'sections/RoadMap/v1';

export default function Home() {
  const { walletModalvisibility, metamaskModal } = useModal();
  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        {/* 토큰소개  */}
        <Banner />
        {/* 플랫폼 소개, 거래소 소개 */}
        <Integration />
        {/* 수익분배 구조 소개 */}
        {/* <Statistics /> */}
        {/* 토큰 획득 방법 */}
        <Features />
        {/* 보유자 혜택 */}
        <Tutorial />
        {/* 로드맵 */}
        <RoadMap />
        {/* 토큰 발행 및 배분 */}
        <StatisticsHor />
        <Footer />
      </Layout>
    </Fragment>
  );
}
